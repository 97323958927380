@font-face {
font-family: 'argent';
src: url(/_next/static/media/36295e6873e18bb5-s.woff2) format('woff2');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: 'argent';
src: url(/_next/static/media/7b75065b8a03dfa8-s.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: 'argent';
src: url(/_next/static/media/e77ad4b9118034d3-s.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: 'argent';
src: url(/_next/static/media/2c276a6af0d2cbd2-s.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: 'argent Fallback';src: local("Arial");ascent-override: 96.95%;descent-override: 22.99%;line-gap-override: 0.00%;size-adjust: 100.05%
}.__className_ec3721 {font-family: 'argent', 'argent Fallback'
}.__variable_ec3721 {--argent: 'argent', 'argent Fallback'
}

@font-face {
font-family: 'avenir';
src: url(/_next/static/media/f4a21323d5448b9d-s.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: 'avenir';
src: url(/_next/static/media/2e54355619b7d62b-s.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: 'avenir';
src: url(/_next/static/media/18bccbd01432d8b0-s.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}@font-face {font-family: 'avenir Fallback';src: local("Arial");ascent-override: 94.34%;descent-override: 24.68%;line-gap-override: 0.00%;size-adjust: 102.92%
}.__className_aabfa1 {font-family: 'avenir', 'avenir Fallback'
}.__variable_aabfa1 {--avenir: 'avenir', 'avenir Fallback'
}

@font-face {
font-family: 'raleway';
src: url(/_next/static/media/564c142b138a8627-s.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: 'raleway Fallback';src: local("Arial");ascent-override: 92.39%;descent-override: 23.00%;line-gap-override: 0.00%;size-adjust: 101.74%
}.__className_aa9e8f {font-family: 'raleway', 'raleway Fallback'
}.__variable_aa9e8f {--raleway: 'raleway', 'raleway Fallback'
}

@font-face {
font-family: 'urbanist';
src: url(/_next/static/media/337e8aee21909225-s.woff2) format('woff2');
font-display: swap;
font-style: normal;
}

@font-face {
font-family: 'urbanist';
src: url(/_next/static/media/71892a2c59b06364-s.woff2) format('woff2');
font-display: swap;
font-style: italic;
}@font-face {font-family: 'urbanist Fallback';src: local("Arial");ascent-override: 94.08%;descent-override: 24.76%;line-gap-override: 0.00%;size-adjust: 100.98%
}.__className_de54bb {font-family: 'urbanist', 'urbanist Fallback', raleway
}.__variable_de54bb {--urbanist: 'urbanist', 'urbanist Fallback', raleway
}

@font-face {
font-family: 'caveat';
src: url(/_next/static/media/b6c7a7abe5e6908a-s.woff2) format('woff2');
font-display: swap;
font-style: normal;
}

@font-face {
font-family: 'caveat';
src: url(/_next/static/media/4abb170af987fa99-s.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: 'caveat Fallback';src: local("Arial");ascent-override: 128.40%;descent-override: 40.12%;line-gap-override: 0.00%;size-adjust: 74.77%
}.__className_1c77f3 {font-family: 'caveat', 'caveat Fallback'
}.__variable_1c77f3 {--caveat: 'caveat', 'caveat Fallback'
}

@font-face {
font-family: 'roboto';
src: url(/_next/static/media/ae85c881ee727311-s.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: 'roboto Fallback';src: local("Arial");ascent-override: 92.43%;descent-override: 24.32%;line-gap-override: 0.00%;size-adjust: 100.37%
}.__className_c81813 {font-family: 'roboto', 'roboto Fallback'
}.__variable_c81813 {--roboto: 'roboto', 'roboto Fallback'
}

@font-face {
font-family: 'notoSerif';
src: url(/_next/static/media/f122d3f36c82f99e-s.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: 'notoSerif Fallback';src: local("Arial");ascent-override: 98.10%;descent-override: 26.89%;line-gap-override: 0.00%;size-adjust: 108.97%
}.__className_4261b6 {font-family: 'notoSerif', 'notoSerif Fallback'
}.__variable_4261b6 {--notoSerif: 'notoSerif', 'notoSerif Fallback'
}

@font-face {
font-family: 'merchant';
src: url(/_next/static/media/641324e5f063df8d-s.woff2) format('woff2');
font-display: swap;
font-weight: 100;
font-style: normal;
}

@font-face {
font-family: 'merchant';
src: url(/_next/static/media/9a722adb7ab46eb8-s.woff2) format('woff2');
font-display: swap;
font-weight: 200;
font-style: normal;
}

@font-face {
font-family: 'merchant';
src: url(/_next/static/media/3b43d78436e509d1-s.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: 'merchant';
src: url(/_next/static/media/9e577edde59f664d-s.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: 'merchant';
src: url(/_next/static/media/d35c1b1350246a9d-s.woff2) format('woff2');
font-display: swap;
font-weight: 900;
font-style: normal;
}@font-face {font-family: 'merchant Fallback';src: local("Arial");ascent-override: 71.97%;descent-override: 19.79%;line-gap-override: 10.00%;size-adjust: 100.05%
}.__className_808cb0 {font-family: 'merchant', 'merchant Fallback', raleway
}.__variable_808cb0 {--merchant: 'merchant', 'merchant Fallback', raleway
}

@font-face {
font-family: 'icons';
src: url(/_next/static/media/b737c0da27996729-s.p.woff2) format('woff2');
font-display: block;
}@font-face {font-family: 'icons Fallback';src: local("Arial");ascent-override: 93.75%;descent-override: 6.25%;line-gap-override: 0.00%;size-adjust: 100.00%
}.__className_dcc6ce {font-family: 'icons', 'icons Fallback'
}.__variable_dcc6ce {--icons: 'icons', 'icons Fallback'
}

